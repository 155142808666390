<template>
  <div class="verfiy-content">
    <div
      class="widgetContainer widgetContainer--center widgetContainer--full-screen appStatus appStatus--verifying verifyOnfido">
      <div class="widgetContainer__body">
        <p class="title-alt" v-if="$te('verifyOnfido_title_alt')">
          {{ $t('verifyOnfido_title_alt') }}
        </p>
        <LottieAnimation animation-name="searching" reference="kyc-status-review-1" :loop="true" />
        <p class="title">
          {{ $t('verifyOnfido_title') }}
        </p>
        <p class="description">
          {{ $t('verifyOnfido_desc') }}
        </p>
        <p class="description" v-if="$te('verifyOnfido_desc_alt')">
          {{ $t('verifyOnfido_desc_alt') }}
        </p>
        <div class="widgetContainer__footer--fixed">
          <el-button
            type="primary"
            class="welcome__get-started el-button__brand brand"
            @click="proceed">
            {{ $t('verifyOnfido_buttonTitle') }}
          </el-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import LottieAnimation from '@/components/LottieAnimation.vue';

export default {
  components: {
    LottieAnimation
  },
  computed: {
    ...mapGetters(['getActiveEnv']),
    ...mapGetters('user', [
      'getPersonDetails',
      'getProgramDetails',
      'getBrandColor'
    ]),
    ...mapGetters('kyc', ['getOnfidoDetails']),
    ...mapGetters('user', ['getUserAuthToken'])
  },
  data() {
    return {
      onfidoOut: null
    };
  },
  created() {
    const loader = this.showLoader();
    const person = this.getPersonDetails;
    if (person && person.id) {
      this.idvStatus(person.id)
        .then((res) => {
          if(res.status === 'approved') {
            this.startKYC();
          } else if(res.status !== 'notStarted') {
            this.$router.push('/kyc/kycStatus');
          }
        }).finally(() => {
          loader.close();
        });
    }
  },
  methods: {
    ...mapActions('kyc', ['fetchVerificationToken', 'submitKyc', 'idvSubmit', 'idvStatus']),
    proceed() {
      const person = this.getPersonDetails;
      const loader = this.showLoader();
      let payload = {
        personId: person.id,
        params: {
        }
      };
      this
        .idvSubmit(payload)
        .then((res) => {
          setTimeout(() => {
            this.startPersona(res.url);
          }, 0);
        })
        .catch((e) => {
          console.log(e);
          this.apiErrorMessage(e);
        })
        .finally(() => {
          loader.close();
        });
      
      window.analytics && window.analytics.track('proceedIdentity');
    },
    startPersona(url) {
      //store session
      window.sessionStorage.setItem('app_env', this.getActiveEnv)
      window.sessionStorage.setItem('user_auth_tokens', JSON.stringify(this.getUserAuthToken));
      window.sessionStorage.setItem('device_id', this.$http.defaults.headers.common['SD-Device-ID']);
      window.location.href = url + '&redirect-uri='+window.location.origin + '/kyc/idv-callback';
    },
    startKYC() {
      /** @type {import('@/modules/user/store/state').userState['personDetails']} */
      const person = this.getPersonDetails;
      if (person && person.id) {
        const loader = this.showLoader();
        this.submitKyc(person.id)
          .then(() => {
            this.$router.push('/kyc/kycStatus');
          })
          .catch((e) => {
            console.log(e);
            this.apiErrorMessage(e);
          })
          .finally(() => {
            loader.close();
          });
      }
    }
  }
}
</script>